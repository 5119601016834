import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import LpComponents from '@components/layout/LpComponents/LpComponents';

// === Content === //
import { LandingPageDTO } from '@DTO/LandingPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface LandingPageProps {
  pageContext?: TemplatePageContextData;
}

const LandingPage = ({ pageContext }: LandingPageProps) => {
  const pageData = LandingPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(pageData.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={pageData.metaData}
        breadcrumbs={null}
        stickedForm={pageData.stickedForm}
        lp={true}
        bannerContactInfo={undefined}
        navLangPath={pageData.langPath}
      >
        <LpComponents components={pageData.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default LandingPage;
